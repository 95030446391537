import React from 'react';
import styled from 'styled-components';
import SEO from 'components/seo';
import CorporateGovernanceLayout from 'layouts/corporate-governance-layout';
import { GoBackButton, MobileHeading } from 'components/misc';

const Shareholders = ()=> {
  return (
    <CorporateGovernanceLayout>
      <SEO title="Walne Zgromadzenia" />
      <GoBackButton path="/investors" name="Dla akcjonariuszy" />
      <MobileHeading heading="Walne Zgromadzenia" />
      <p>Brak sprawozdań</p>
    </CorporateGovernanceLayout>
  );
};

export default Shareholders;
